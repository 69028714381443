import { useJsonld } from '#jsonld';

export function useStructuredLogo() {
    useJsonld({
        '@context': 'https://schema.org',
        '@type': 'Organization',
        url: 'https://luma-delikatessen.ch',
        logo: 'https://luma-delikatessen.ch/static/logo-black.svg',
        name: 'LUMA Delikatessen',
        legalName: 'Luma Beef AG',
        telephone: '+41 (0)52 670 02 11',
        email: 'info@luma-delikatessen.ch',
        address: [
            {
                '@type': 'PostalAddress',
                streetAddress: 'Gewerbestrasse 6',
                addressLocality: 'Neuhausen am Rheinfall',
                postalCode: '8212',
                addressCountry: 'CH',
            },
        ],
    });
}
