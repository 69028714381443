<template>
    <div v-if="page?.body.length > 0" class="spacer-top">
        <CmsBody :page="page" />
    </div>
</template>

<script setup>
import { useStructuredLogo } from '~/composables/structuredData/logo';
import {
    getOgDescription,
    getOgTitle,
    useOpenGraph,
} from '~/composables/seo/opengraph';
import { useRobotsMeta } from '~/composables/seo/robotsMeta';
import { useHrefLang } from '~/composables/seo/hreflang';
import { useWagtailClient } from '~/composables/useWagtailClient';

const route = useRoute();
const wagtail = useWagtailClient();

const props = defineProps({
    pageObj: Object,
});

const page = toRef(props, 'pageObj');

useHead({
    title: page?.value?.meta?.seo_title
        ? page?.value?.meta?.seo_title
        : `${page?.value?.title} | LUMA Delikatessen`,
});

// structured data
useHrefLang(page?.value?.meta?.hreflang);
useStructuredLogo();
const openGraphImage = ref('');
const img = useImage();
if (page?.value?.open_graph_image_url) {
    openGraphImage.value = img(page?.value?.open_graph_image_url, {
        width: 1200,
        height: 630,
        format: 'jpeg',
    });
}
useOpenGraph(
    getOgTitle(page),
    getOgDescription(page),
    openGraphImage.value,
    page?.value?.meta?.html_url,
);
useRobotsMeta(page?.value?.robots_meta_tag);
// end structured data
</script>

<style lang="scss">
.spacer-top {
    margin-top: clamp(15px, 3.82vw + 2.78px, 70px);
}
::selection {
    color: inherit;
    background: rgba(128, 128, 128, 0.2);
}
</style>
